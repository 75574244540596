import React, { useContext, useState, useRef, useCallback, useEffect } from "react";
// Util
import {useParams, useNavigate, useLocation} from "react-router";
import ProjectStore from "../../../../../ProjectCanvas/ProjectStore";
// Canvas
import { CanvasContext } from "../../../WorkflowCanvas";
// Custom components
import CanvasReloadDialog from "../../../CanvasReloadDialog";
import ComponentSettingsToolbar from './ComponentNodeUtil/ComponentSettingsToolbar';
import ComponentNodeView from './ComponentNodeUtil/ComponentNodeView';
// MUI
import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
// Icons
import Error from "@mui/icons-material/Error";
// MobX
import { inject, observer } from "mobx-react";
import InstructionsInfo from "../../../InstructionInfo";
import {CustomDialog} from "../../../../../Component/Dialog";
import { styles } from './ComponentNodeUtil/CustomComponentNodeUtil';
import BaseComponent from "./BaseComponent";
import {send_request} from "../../../../../../utils/Request";

const ProductionCanvasComponent = inject("CanvasStore")(
  observer(({ id, data, classes, selected, CanvasStore }) => {

    const { modeInfo } = useContext(CanvasContext);
    if (modeInfo === undefined) {
      return <BaseComponent classes={classes} data={data} isConnectable={isConnectable} />;
    }

    const location = useLocation();
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);

    const parentRef = useRef(null);
    const ref = useRef(null);
    const { nodes } = CanvasStore;
    const { edit, forceStart, handleProductionCanvasMount } = useContext(CanvasContext);
    const { productionVersion } = useContext(CanvasContext);
    const params = useParams();
    const projectId = params.id;
    const navigate = useNavigate();
    const [mode, setMode] = modeInfo;


    // State hooks
    const [editComponent, setEditComponent] = edit ? edit : useState({});
    const [forceStartComponent, setForceStartComponent] = forceStart ? forceStart : useState({});
    const [isSelectedComponent, setIsSelectedComponent] = useState(false);
    const [isSelectedForceStart, setIsSelectedForceStart] = useState(false);
    const [svgSelectedClass, setSvgSelectedClass] = useState("");
    const [showCheckingLatestDialog, setShowCheckingLatestDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [draftVersion, setDraftVersion] = useState("1");


    // Effect hooks
    useEffect(() => {
      const dataComponentId = (data && data.componentId) ? data.componentId : id;
      setIsSelectedComponent((editComponent && dataComponentId === editComponent.componentId));
    }, [editComponent, data, id]);

    useEffect(() => {
      setIsSelectedForceStart(selected || (forceStartComponent && !selected && id === forceStartComponent.componentId));
    }, [forceStartComponent, selected, id]);

    useEffect(() => {
      const isTrue = selected || isSelectedComponent || isSelectedForceStart;
      setSvgSelectedClass(isTrue ? classes.selectedIcon : "");
    }, [isSelectedComponent, isSelectedForceStart, selected, classes.selectedIcon]);

    function handleCloseForceStartPane() {
      if (window.location.href.includes("?force_start=")) navigate(`/project/production/${projectId}`);
      setForceStartComponent(null);
    }
    const getLatestVersion = async (projectId) => {

      return send_request(`project-service/project/last-version/${projectId}`, "", "", "get").then((res) => {
        if (!res || !res.data) return;
        let { draftVersion } = res.data;
        if (draftVersion) {
          setDraftVersion(draftVersion);
          ProjectStore.draftVersion = draftVersion;
        }


        // Look for force_start pane open in URL
        let componentId = queryParams.get("force_start");
        if (!componentId) return;

        // Try to find component
        let openedComponent = ProjectStore.canvas.find((comp) => comp.id === componentId);

        if (!openedComponent) {
          // Component doesn't exist in production version
          navigate(`/project/production/${projectId}`);
          return;
        }

        // Else we found it! Set it to force start state
        setForceStartComponent({
          componentId: componentId,
          ...openedComponent.data,
        });
      });
    };

    const handleNodeClick = useCallback((event) => {
      event.stopPropagation();
      if (forceStartComponent){
        handleCloseForceStartPane();
        return;
      }

      queryParams.set("force_start", id);
      let newUrl = `${location.pathname}?${queryParams.toString()}`;
      navigate(newUrl);
      getLatestVersion(projectId);

    }, [forceStart, forceStartComponent, data, id]);

    // Handlers
    const handlePreview = useCallback(() => {
      ProjectStore.state.production = true;
      ProjectStore.state.canvasView = false;
      if (edit && !editComponent) {
        setEditComponent({ ...data, componentId: id });
      }
    }, [edit, editComponent, data, id]);

    const handleRun = useCallback(() => {
      if (!data) return;
      setShowCheckingLatestDialog(true);
    }, [data]);

    const handleOpenEditable = async () => {
      let url;

      if (data.fromWorkflowPath)
        url = `/project/canvas/${projectId}?view=${data.parentId}`;
      else {
        url = `/project/canvas/${projectId}?view=${id}`;
      }

      ProjectStore.state.canvasView = false;
      ProjectStore.state.production = false;

      navigate(url);
    };

    const nodeSettingsMenuItems = [
      {
        title: "Run from this component",
        icon: "play_circle",
        onClick: handleRun
      },
      {
        title: "View component",
        icon: "preview",
        onClick: handlePreview
      },
      {
        title: "Edit this component",
        icon: "edit",
        onClick: () => setShowEditDialog(true)
      }
    ];

    return (
      <>

        <div
            draggable={false}
            ref={parentRef}
            onDragOver={(event) => {
              event.preventDefault();
            }}
            className={classes.nodeWrapper}
        >
          <div onClick={handleNodeClick}>
            {data.error && (
                <Error className={`error-text ${classes.downsize}`}></Error>
            )}
            <ComponentNodeView
              classes={classes}
              data={data}
              selected={selected}
              isSelectedComponent={isSelectedComponent}
              isSelectedForceStart={isSelectedForceStart}
              isProdCanvas={true}
              mode={mode}
              forceStartComponent={forceStartComponent}
            />
          </div>

            <ComponentSettingsToolbar
                selected={selected}
                actions={nodeSettingsMenuItems}
                classes={classes}
                data={data}
            />

            {data.instructions &&
                (data.instructions.buildInstruction ||
                    data.instructions.editInstruction) && (
                    <InstructionsInfo
                        data={data}
                        parent={parentRef}
                        textRef={ref}
                    />
                )}
          </div>
          {/* Dialogs */}
          {showEditDialog && (
              <CustomDialog
                  isOpen={showEditDialog}
                  title="↩️ You will be switched to the draft canvas"
                  contents={
                    <p>
                      To edit this component, you will be switched back to the
                      draft canvas. Make sure to save and publish your changes
                      before switching back to the production canvas.
                    </p>
                  }
                  buttons={
                    <>
                      <Button
                          onClick={() => {
                            setShowEditDialog(false);
                          }}
                          variant="outlined"
                      >
                        Cancel
                      </Button>
                      <Button
                          onClick={() => {
                            handleOpenEditable().then(() => {});
                          }}
                          variant="contained"
                      >
                        Continue
                      </Button>
                    </>
                  }
              />
          )}
          {showCheckingLatestDialog && (
              <CanvasReloadDialog
                  projectId={projectId}
                  currentProdVersion={productionVersion}
                  setShowCheckingLatestDialog={setShowCheckingLatestDialog}
                  data={data}
                  id={id}
                  forceStartComponent={forceStartComponent}
                  setForceStartComponent={setForceStartComponent}
                  handleProductionCanvasMount={handleProductionCanvasMount}
              />
          )}
      </>
    );
  })
);

export default withStyles(styles)(ProductionCanvasComponent);
