import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Grid } from "@mui/material";
/**
 * A toolbar component for displaying component settings on component hover.
 */
const ComponentSettingsToolbar = ({ selected, actions, classes, data, enableWorkflowPath = false }) => {
  if (!selected || (data.fromWorkflowPath && !enableWorkflowPath)) return null;

  return (
    <div className={classes.nodeInfoBackground}>
      <div className={classes.settings}>
        <Grid container>
          <Grid item xs={12} className={classes.centerGrid}>
            {actions.map((action, index) => (
              <Tooltip key={index} title={action.title}>
                <i
                  className="material-icons icon"
                  onClick={action.onClick}
                >
                  {action.icon}
                </i>
              </Tooltip>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

ComponentSettingsToolbar.propTypes = {
  selected: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
  })).isRequired,
  classes: PropTypes.shape({
    nodeInfoBackground: PropTypes.string.isRequired,
    settings: PropTypes.string.isRequired,
    centerGrid: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    fromWorkflowPath: PropTypes.bool,
  }).isRequired,
  enableWorkflowPath: PropTypes.bool,
};

export default ComponentSettingsToolbar;