import React, { useState, useEffect } from "react";
import APIResReqStore from "../APIResReqStore";
import { observer } from "mobx-react";
import { MenuItem, Button, Grid, styled, Typography, Box, Autocomplete, TextField } from "@mui/material";
import {send_request} from "../../../utils/Request";
import MentionEditor from "../../TextEditor/MentionEditor";
import DynamicIcons from "../../CredentialStore/DynamicIcons";
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';

const GenerateCurlButton = styled(Button)({
  '&.Mui-disabled': {
    background: '#DCDCDC',
  },
  '&:not(.Mui-disabled)': {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
  },
  width: "100%",
  height: "34px",
  padding: "8px 24px 8px 24px",
  gap: "10px",
  borderRadius: "4px",
  textTransform: 'none',
});

const SelectLabel = styled(Typography)({
  paddingBottom: '8px',
  fontFamily: 'Open Sans !important',
  fontSize: '14px',
  fontWeight: '400 !important',
  lineHeight: '19.07px',
  textAlign: 'left',
});

const DescriptionLabel = styled(Typography)({
  paddingBottom: '8px',
  fontFamily: 'Open Sans !important',
  fontSize: '14px',
  fontWeight: '400 !important',
  lineHeight: '19.07px',
  textAlign: 'left',
  color: '#00000080',
});

const CredentialsMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  '& > *:first-of-type': {
    marginRight: '16px'
  }
});

const TitleText = styled(Typography)({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: '700 !important',
  lineHeight: '19.07px',
  textAlign: 'left',
  color: '#000000',
});

const ConnectAppButton = styled(Button)({
  height: '32px',
  padding: '6px 16px 6px 16px',
  borderRadius: '4px',
  backgroundColor: 'black',
  color: 'white',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#333333',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minWidth: '117px',
  '& .MuiButton-startIcon': {
    marginLeft: '0',
    marginRight: '8px',
  },
});

const CredentialRefreshButton = styled(Button)({
  width: '72px',
  height: '32px',
  padding: '6px 16px',
  gap: '4px',
  borderRadius: '4px',
  border: '1px solid',
  opacity: 1,
  textTransform: 'none',
  marginLeft: '8px',
});

const ACTION_TEMPLATES_CREDENTIALS_URL = "credential-store/credential/user/action-templates";
const DEFAULT_SECRET_PLACEHOLDER = "${access_token.SECRET}";
const CREDENTIAL_PAGE_URL = '/credential_store';
const BLANK = '_blank';

const NO_CREDENTIAL_TEXT = "No credentials available";
const SELECT_ACTION_PLACEHOLDER = "Select Action";
const CURL_SETUP_MODE = "CURL";
const SELECT_APP_PLACEHOLDER = "Select App";
const SELECT_CREDENTIALS_PLACEHOLDER = "Select Credential";
const ON_FOCUS_INPUT = "rawResponsePlaceholderName";
const CurlTemplatesSetup = observer(({availablePlaceholders, setOnFocus}) => {
  const [selectedApp, setSelectedApp] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [fields, setFields] = useState([]);
  const [curl, setCurl] = useState("");
  const [userCredentials, setUserCredentials] = useState([]);
  const [secretPlaceholders, setSecretPlaceholders] = useState([]);
  const [selectedCredential, setSelectedCredential] = useState("");
  const [templates, setTemplates] = useState([]);

  const getUserCredentials = async () => {
    try {
      const res = await send_request(ACTION_TEMPLATES_CREDENTIALS_URL, "", "", "GET");
      if (!res && !res.data) return;

      const data = res.data;
      const credentials = data.credentials;
      const actionTemplates = data.actionTemplates;

      if (credentials) {
        const credentialData = credentials
            .filter(credential => credential?.data?.integrationType)
            .map((credential) => ({
              ...credential,
              data: credential.data || {},
            }));

        setUserCredentials(credentialData);
      }

      if (actionTemplates){
        const processedActionTemplates = actionTemplates.map(template => ({
          ...template,
          actions: template.actions.map(action => ({
            ...action,
            fields: action.fields.map(field => ({
              ...field,
              value: field.defaultValue || "",
            }))
          }))
        }));
        setTemplates(processedActionTemplates);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setSecretPlaceholderByApp = (appName) => {
    const placeholderList = userCredentials.filter(credential => credential.data.integrationType === appName);
    setSecretPlaceholders(placeholderList);
  }

  useEffect(()=> {
    setSecretPlaceholderByApp(selectedApp);
  },[selectedApp, userCredentials])

  useEffect(()=>{
    getUserCredentials();
  },[])

  const handleAppChange = (event, newValue) => {
    setSelectedApp(newValue ? newValue.appName : "");
    setSelectedAction("");
    setFields([]);
    setCurl("");
  };

  const handleActionChange = (event, newValue) => {
    if (!newValue) {
      setSelectedAction("");
      setFields([]);
      setCurl("");
      return;
    }
    const app = templates.find(t => t.appName === selectedApp);
    const action = app.actions.find(a => a.name === newValue);
    setSelectedAction(newValue);
    setFields(action.fields);
    setCurl(action.curl);
  };

  const handleFieldChange = (index, newValue, field, availablePlaceholders) => {
    const containsValidPlaceholder = availablePlaceholders[0].placeholders?.some(placeholder =>
      newValue.includes(`\${${placeholder.key}}`));

    let isValid = true;
    if (!containsValidPlaceholder && field.regex) {
      const valueForRegexCheck = newValue.replace(/\$\{[^}]+\}/g, '');
      isValid = new RegExp(field.regex).test(valueForRegexCheck);
    }

    const newFields = [...fields];
    newFields[index].value = newValue;
    newFields[index].error = isValid ? null : `Invalid input for ${field.name}! Must satisfy ${field.regex}`;
    setFields(newFields);
  };

  const handleCredentialChange = (event, newValue) => {
    setSelectedCredential(newValue ? newValue.value : "");
  };

  const generateRequest = () => {
    let generatedCurl = curl;
    fields.forEach(field => {
      const regex = new RegExp(`{{${field.placeholder||field.name}}}`, "g");
      generatedCurl = generatedCurl.replace(regex, field.value);
    });

    if (selectedCredential) {
      generatedCurl = generatedCurl.replace(DEFAULT_SECRET_PLACEHOLDER, "${"+selectedCredential+".SECRET}");
    }

    APIResReqStore.setRequestCurl(generatedCurl);
  };

  const hasFieldErrors = fields.some(field => field.error);
  const hasEmptyRequiredFields = fields.some(field => field.required === true && !field.value);
  const handleConnectApp = () => {
    window.open(CREDENTIAL_PAGE_URL, BLANK);
  };

  return (
    <div className="setup-mode-container" hidden={APIResReqStore.requestData.setupMode !== CURL_SETUP_MODE} style={{ width: "100%"}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleText>
            Load an integrations template
          </TitleText>
        </Grid>
        <Grid item spacing={2} xs={12}>
          <SelectLabel>
            Select an app
          </SelectLabel>
          <Autocomplete
            value={
              templates.find(app => app.appName === selectedApp) || null
            }
            onChange={handleAppChange}
            options={templates}
            getOptionLabel={(option) => option.appName}
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={SELECT_APP_PLACEHOLDER}
                size="small"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {selectedApp && (
                        <Box
                          paddingLeft="8px"
                          paddingRight="8px"
                          display="flex"
                          alignItems="center"
                          height="100%"
                        >
                          <DynamicIcons icons={[templates.find(app => app.appName === selectedApp)?.icon]} />
                        </Box>
                      )}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <CredentialsMenuItem {...props}>
                <DynamicIcons icons={[option.icon]} />
                {option.appName}
              </CredentialsMenuItem>
            )}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <SelectLabel>
            Select a credential
          </SelectLabel>
          {selectedApp && secretPlaceholders.length === 0 ? (
            <>
              <Typography variant="body2" style={{ marginBottom: '10px', paddingTop: '8px' }}>
                No Credentials available for {selectedApp}
              </Typography>
              <Box display="flex" alignItems="center">
                <ConnectAppButton
                  variant="contained"
                  onClick={handleConnectApp}
                  startIcon={<ElectricalServicesIcon />}
                >
                  Connect app
                </ConnectAppButton>
                <CredentialRefreshButton
                  variant="outlined"
                  onClick={getUserCredentials}
                >
                  Refresh
                </CredentialRefreshButton>
              </Box>
            </>
          ) : (
            <Autocomplete
              value={selectedCredential ? { label: `\${${selectedCredential}.SECRET}`, value: selectedCredential } : null}
              onChange={handleCredentialChange}
              options={secretPlaceholders.map(credential => ({
                label: `\${${credential.data.placeholder}.SECRET}`,
                value: credential.data.placeholder
              }))}
              renderInput={(params) => <TextField {...params} placeholder={SELECT_CREDENTIALS_PLACEHOLDER} size="small" />}
              disabled={!selectedApp}
              fullWidth
              noOptionsText={NO_CREDENTIAL_TEXT}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <SelectLabel>
            Select an action
          </SelectLabel>
          <Autocomplete
            value={selectedAction}
            onChange={handleActionChange}
            options={selectedApp ? templates.find(t => t.appName === selectedApp).actions.map(action => action.name) : []}
            renderInput={(params) => <TextField {...params} placeholder={SELECT_ACTION_PLACEHOLDER} size="small" />}
            disabled={!selectedApp}
            fullWidth
            autoHighlight
          />
        </Grid>

        {fields.length > 0 &&
            <Grid item>
              <DescriptionLabel style={{paddingBottom: '0px !important'}}>
                Fields
              </DescriptionLabel>
            </Grid>
        }

        {fields.map((field, index) => {
          return (  
            <Grid item xs={12} key={index}>
              <SelectLabel>
                {`${field.name} ${field.required === true ? "*" : ""}`}
              </SelectLabel>
              <DescriptionLabel>
                {field.description}
              </DescriptionLabel>
              <MentionEditor
                  availablePlaceholders={availablePlaceholders}
                  value={field.value || ""}
                  text={field.value || ""}
                  onChange={(newValue) => handleFieldChange(index, newValue, field, availablePlaceholders)}
                  onFocus={() => {
                    setOnFocus(ON_FOCUS_INPUT);
                  }}
              />
              {field.error && (
                <Typography color="error" variant="caption" style={{ marginTop: '4px' }}>
                  {field.errorMessage || field.error}
                </Typography>
              )}
            </Grid>
          )}
        )}
        <Grid item xs={12}>
          <GenerateCurlButton
            variant="contained" 
            onClick={generateRequest} 
            disabled={!selectedAction || !selectedCredential || hasEmptyRequiredFields}
            style={{
              width: "100%",
              height: "34px",
              padding: "8px 24px 8px 24px",
              gap: "10px",
              borderRadius: "4px",
              textTransform: 'none',
            }}
          >
            Load Template
          </GenerateCurlButton>
        </Grid>
      </Grid>
    </div>
  );
});

export default CurlTemplatesSetup;