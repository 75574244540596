
const awsconfig = {
  REGION: process.env.REACT_APP_REGION || "ap-southeast-2",
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID || "ap-southeast-2_Kp9DpRrdK",
  APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID || "5f93lfrqqh7nruhjiiin8qbeem",
  DOMAIN: process.env.REACT_APP_DOMAIN || "w86dev.click",
};

const aws_config = {
  Auth: {
    Cognito: {
      userPoolId: awsconfig.USER_POOL_ID,
      userPoolClientId: awsconfig.APP_CLIENT_ID,
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          domain: 'authenticate.app.w86dev.click',
          scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: ['https://app.w86dev.click'],
          redirectSignOut: ['https://app.w86dev.click'],
          responseType: 'code',
        },
        username: true,
        email: true,
        phone: true,
      },
    },
    cookieStorage: {
      domain: window.location.host.includes("localhost")
        ? "localhost"
        : awsconfig.DOMAIN,
      path: "/",
      expires: 1,
      secure: false
    }
  },
  gg_client_id: "541992205730-ombu26ut0amuo794b59bku1kt08egofm.apps.googleusercontent.com",
};

// Keeping additional properties for reference, but not including in the main config
const additionalConfig = {
  mandatorySignId: true,
  cookieStorage: {
    domain: window.location.host.includes("localhost")
      ? "localhost"
      : awsconfig.DOMAIN,
    path: "/",
    expires: 1,
    secure: false
  },
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: "ap-southeast-2_Kp9DpRrdK",
  aws_user_pools_web_client_id: "5f93lfrqqh7nruhjiiin8qbeem",
  clientId: "541992205730-ombu26ut0amuo794b59bku1kt08egofm.apps.googleusercontent.com",
};

export default aws_config;

